import { Link } from "react-router-dom";
import { path } from "../urls";
import {
  BsBasket,
  BsEmojiHeartEyes,
  BsFillArrowUpSquareFill,
  BsFillCartCheckFill,
  BsHeart,
  BsHourglass,
  BsShop,
  BsStar,
} from "react-icons/bs";
import { BiHeart, BiUser } from "react-icons/bi";
import { GiProfit } from "react-icons/gi";
import { HiLightBulb } from "react-icons/hi";
import { MdAvTimer } from "react-icons/md";
import {
  RiCheckLine,
  RiCoupon3Fill,
  RiCouponLine,
  RiGitRepositoryCommitsFill,
  RiProfileLine,
} from "react-icons/ri";
import {
  FaCheckCircle,
  FaRegAddressCard,
  FaRegIdBadge,
  FaTv,
} from "react-icons/fa";
import {useRef} from 'react';
import { MdFeedback } from "react-icons/md";
import { FaWallet } from "react-icons/fa";
import {
  CiBookmarkCheck,
  CiCreditCard1,
  CiUser,
  CiWallet,
} from "react-icons/ci";
import { MdHourglassFull } from "react-icons/md";
import { BsStarFill } from "react-icons/bs";
import _axios from "../axios";
import buildLink from "../urls";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { FaSignOutAlt } from "react-icons/fa";
import { AccountContext } from "../contexts/AccountContext";
import { useContext, useEffect, useState } from "react";
import { CurrencyContext } from "../contexts/CurrencyContext";
import { IoIosClose, IoIosLogOut, IoIosTimer } from "react-icons/io";
import { FcFeedback } from "react-icons/fc";
import { IoBulbOutline } from "react-icons/io5";
import { ImProfile } from "react-icons/im";
import { AiOutlineProfile } from "react-icons/ai";
import { VscFeedback } from "react-icons/vsc";

function MyAccount(props) {
  const [state, dispatch] = useContext(AccountContext);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const statuses = ["complete", "processing", "trash"];
  const dataInformation = useContext(CurrencyContext);
  const router = useNavigate();
  const handleTabClick = (selectedStatus) => {
    router.push(`/account/orders?status=${selectedStatus}`, undefined, {
      shallow: true,
    });
  };

  async function logout(type) {
    try {
      dispatch({ type: "setLoading", payload: true });
      await _axios.post(buildLink("logout"));
      dispatch({ type: "setSeller", payload: false });
      Cookies.remove("seller_id");
      router("/");
      window.location.reload();
    } catch (error) {
    } finally {
      dispatch({ type: "setLoading", payload: false });
    }
  }

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const scrollContainerRef = useRef(null);
  const scrollContainerRef1 = useRef(null);
  const [activeBar, setActiveBar] = useState(0); 
  const [activeBar1, setActiveBar1] = useState(0);

  const handleScroll = () => {
    if (!scrollContainerRef.current) return;

    const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
    const atEnd = scrollLeft + clientWidth >= scrollWidth - 10; 
    setActiveBar(atEnd ? 1 : 0); 
  };
  
  const handleScroll1 = () => {
    if (!scrollContainerRef1.current) return;

    const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef1.current;
    const atEnd1 = scrollLeft + clientWidth >= scrollWidth - 10;
    setActiveBar1(atEnd1 ? 1 : 0);
  };


  return (
    <div className="flex flex-col p-2 md:hidden space-y-2">
      {/* Account Management Section */}
      <div className="flex flex-col space-y-2 bg-white rounded-lg p-4 shadow-sm">
            <h2 className="text-lg font-bold  border-b pb-2 border-b-dgreyQty1Product">
        <span style={{color:"black"}}>Account Management</span>
      </h2>

        {/* <div className="flex justify-between items-start"> */}
        <div className="grid grid-cols-5 gap-24 ml-10">
          <Link to={`${path}/account/profile`}>
            <div className="flex flex-col items-center justify-center h-24 rounded-lg">
              <div className="flex justify-center items-center w-10 h-10 rounded-full mb-1">
                <BiUser className="text-2xl text-black" />
              </div>
              <span className=" text-sm">Profile</span>
            </div>
          </Link>
          <Link to={`${path}/account/addresses`}>
            <div className="flex flex-col items-center justify-center h-24 rounded-lg">
              <div className="flex justify-center items-center w-10 h-10 rounded-full mb-1">
                <FaRegAddressCard className="text-2xl text-black "/>
              </div>
              <span className=" text-black text-sm">
                Addresses
              </span>
            </div>
          </Link>
          <Link to="/" onClick={() => logout()}>
            <div className="flex flex-col items-center justify-center h-24 rounded-lg">
              <div className="flex justify-center items-center w-10 h-10 rounded-full mb-1">
                <IoIosLogOut className="text-2xl text-black" />
              </div>
              <span className=" text-black text-sm">Logout</span>
            </div>
          </Link>
        </div>
      </div>
      {/* </div> */}

      {/* Status Section */}
      <div className="flex flex-col space-y-2 bg-white rounded-lg p-4 shadow-sm">
        <h2 className="text-lg border-b  font-bold border-b-dgreyQty1Product pb-2">
         <span style={{color:"black"}}>Orders</span> 
        </h2>
        <div className="grid grid-cols-5 gap-24 ml-10">
          {" "}
          {/* Added gap-4 for spacing */}
          <Link to="/account/orders?status=processing">
            <div className="flex flex-col items-center justify-center h-24 rounded-lg transition duration-200 hover:p-2">
              <div className="flex justify-center items-center w-10 h-10 rounded-full mb-1">
                {/* Icon for Processing */}
                <BsHourglass className="text-2xl text-black" />
              </div>
              <span className=" text-black text-sm">
                Processing
              </span>
            </div>
          </Link>
          <Link to="/account/orders?status=complete">
            <div className="flex flex-col items-center justify-center h-24 rounded-lg transition duration-200 hover:p-2">
              <div className="flex justify-center items-center w-10 h-10 rounded-full mb-1">
                {/* Icon for Completed */}
                <CiBookmarkCheck className="text-2xl text-black"/>
              </div>
              <span className=" text-black text-sm">
                Completed
              </span>
            </div>
          </Link>
          <Link to="/account/orders?status=trash">
            <div className="flex flex-col items-center justify-center h-24 rounded-lg transition duration-200 hover:p-2">
              <div className="flex justify-center items-center w-10 h-10 rounded-full mb-1">
                {/* Icon for Cancelled */}
                <IoIosClose className="text-6xl text-black" style={{ color: "#4d4d4d" }}/>
              </div>
              <span className=" text-black text-sm">
                Cancelled
              </span>
            </div>
          </Link>
        </div>
      </div>

      {/* Services Section */}
      <div className="flex flex-col space-y-2 bg-white rounded-lg p-4 shadow-sm">
        <h2 className="text-lg border-b  font-bold border-b-dgreyQty1Product pb-2">
         <span style={{color:"black"}}>Services</span> 
        </h2>
        <div className="relative">
          {/* Grid container with horizontal scroll */}
          <div
   
      className="grid grid-flow-col gap-6 ml-1"

    >
    

      {/* Dynamic Border */}
      

            <Link to={`${path}/account/memberShip`}>
              <div className="flex flex-col items-center justify-center h-24 rounded-lg transition duration-200 hover:bg-gray-100">
                <div className="flex justify-center items-center w-10 h-10 rounded-full mb-1">
                  <FaRegIdBadge className="text-2xl text-black"  style={{color:"#4d4d4d"}}/>
                </div>
                <span className=" text-black text-sm">
                  Membership
                </span>
              </div>
            </Link>
            <Link to={`${path}/account/wallet`}>
              <div className="flex flex-col items-center justify-center h-24 rounded-lg transition duration-200 hover:bg-gray-100 p-2">
                <div className="flex justify-center items-center w-10 h-10 rounded-full mb-1">
                  <CiWallet className="text-2xl text-black" style={{color:"black"}} />
                </div>
                <span className=" text-black text-sm">Wallet</span>
              </div>
            </Link>
            {state.allow_pay_by_saved_card && (
              <Link to="/account/paymentcards">
                <div className="flex flex-col items-center justify-center h-24 rounded-lg transition duration-200 hover:bg-gray-100 p-2">
                  <div className="flex justify-center items-center w-10 h-10 rounded-full mb-1">
                    <CiCreditCard1 className="text-2xl text-black" />
                  </div>
                  <span className=" text-black text-sm">
                    Cards
                  </span>
                </div>
              </Link>
            )}
          
            <Link to={`${path}/account/coupons`}>
              <div className="flex flex-col items-center justify-center h-24 rounded-lg transition duration-200 hover:bg-gray-100 p-2">
                <div className="flex justify-center items-center w-10 h-10 rounded-full mb-1">
                  <RiCouponLine className="text-2xl text-black"  style={{ color: "#333333" }}/>
                </div>
                <span className=" text-black text-sm">
                  Coupons
                </span>
              </div>
            </Link>
            <Link to={`${path}/account/checkin`}>
              <div className="flex flex-col items-center justify-center h-24 rounded-lg transition duration-200 hover:bg-gray-100 p-2">
                <div className="flex justify-center items-center w-10 h-10 rounded-full mb-1">
                  <RiCheckLine className="text-2xl text-black" style={{ color: "#333333" }}/>
                </div>
                <span className=" text-black text-sm">
                  Checkin
                </span>
              </div>
            </Link>
            {dataInformation.use_claim_points_service && (
              <div
                className="flex flex-col items-center justify-center h-24 rounded-lg transition duration-200 hover:bg-gray-100 p-2 cursor-pointer"
                onClick={handleOpenPopup}
              >
                <div className="flex justify-center items-center w-10 h-10 rounded-full mb-1">
                  <GiProfit className="text-2xl text-black" style={{ color: "#4d4d4d" }}/>
                </div>
                <span className="whitespace-nowrap  text-black text-sm">
                  Claim Points
                </span>
               
              </div>
            )}
          </div>
          
        </div>
      </div>

      {/* Wishlist , Review and Recently Viewed Section */}
      <div className="flex flex-col space-y-2 bg-white rounded-lg p-4 shadow-sm">
        <h2 className="text-lg border-b  font-bold pb-2 border-b-dgreyQty1Product">
          <span style={{color:"black"}}>Product And Review</span>
        </h2>
        <div className="relative">
          {/* Grid container with horizontal scroll */}
          <div
      className="grid grid-flow-col gap-3 overflow-x-auto ml-1"
    >
      {/* Dynamic Border */}

            <Link to={`${path}/account/wishlist`}>
              <div className="flex flex-col items-center justify-center h-24 rounded-lg transition duration-200 hover:bg-gray-100 ">
                <div className="flex justify-center items-center w-10 h-10 rounded-full mb-1">
                  <BiHeart className="text-2xl text-black" />
                </div>
                <span className=" text-black text-sm">
                  Wishlist
                </span>
              </div>
            </Link>
            <Link  to={`${path}/account/recentlyViewed`}>
              <div className="flex flex-col items-center justify-center h-24 rounded-lg transition duration-200 hover:bg-gray-100 p-2">
                <div className="flex justify-center items-center w-10 h-10 rounded-full mb-1">
                  <IoIosTimer className="text-2xl text-black" />
                </div>
                <span className=" text-black text-sm whitespace-nowrap">
                  Recently Viewed
                </span>
              </div>
            </Link>
            <Link to={`${path}/account/reviewCenter`}>
              <div className="flex flex-col items-center justify-center h-24 rounded-lg transition duration-200 hover:bg-gray-100 p-2">
                <div className="flex justify-center items-center w-10 h-10 rounded-full mb-1">
                  <BsStar className="text-2xl text-black" />
                </div>
                <span className=" text-black text-sm whitespace-nowrap">
                  Reviews
                </span>
              </div>
            </Link>
            
          </div>
        </div>
      </div>

      {/* Feedback and Suggestion Section */}
      <div className="flex flex-col space-y-2 bg-white rounded-lg p-4 shadow-sm">
        <h2 className="text-lg border-b  font-bold  pb-2 border-b-dgreyQty1Product">
          <span style={{color:"black"}}>Feedback And Suggestions</span>
        </h2>
        <div className="grid grid-cols-5 gap-24 ml-10">
          <Link  to={`${path}/account/feedback`}>
            <div className="flex flex-col items-center justify-center h-24 rounded-lg transition duration-200 hover: p-1">
              <div className="flex justify-center items-center w-10 h-10 rounded-full mb-1">
                <VscFeedback className="text-2xl text-black" />
              </div>
              <span className=" text-black text-sm">Feedback</span>
            </div>
          </Link>
          <Link  to={`${path}/account/suggestion`}>
            <div className="flex flex-col items-center justify-center h-24 rounded-lg transition duration-200 hover: p-1">
              <div className="flex justify-center items-center w-10 h-10 rounded-full mb-1">
                <HiLightBulb className="text-2xl text-black" />
              </div>
              <span className=" text-black text-sm">Suggestion</span>
            </div>
          </Link>
          
        </div>
      </div>
    </div>
  );
}

export default MyAccount;
