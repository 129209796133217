
import React, { useContext, useEffect, useState } from "react";

import { AiOutlineRight, AiOutlineStar } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import { Link, useNavigate } from "react-router-dom";
import { AccountContext } from "../contexts/AccountContext";
import PointsLoader from "../components/PointsLoader";
import UserSidebar from "../components/UserSidebar";
import UserSidebarMobile from "../components/UserSidebarMobile";
import { slugify } from "../Utils";
import SeoHandler from "../components/SeoHandler";
import { useWindowDimensions } from "../components/TopHeader";
import _axios from "../axios";
import buildLink from "../urls";
import SingleProducts from "../components/SingleProduct";

export default function MenmberShip() {
  const [state, setState] = useState(null);
  const [suggestions, setSuggesstions] = useState(null);
  const { width } = useWindowDimensions();
  const [stateAcc, dispatchAcc] = useContext(AccountContext);
  const [totalPage, setTotalPage] = useState(0);
  const [limit, setLimit] = useState(20);
  const  noAddCart= true;
  const [page, setPage] = useState(0);
  const router = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!stateAcc.loading && !stateAcc.loged) {
      router("/");
    } else if (stateAcc.loged) {
      const urlParams = new URLSearchParams(window.location.search);
      const page = urlParams.get('page');


      let pageparam = ''
      if (page) {
        pageparam = '&page=' + page
        setPage(page)
      } else {
        setPage(0)
      }

      setLoading(true)
      _axios
        .get(buildLink("memberShip") + pageparam + "&limit=" + limit)
        .then((resp) => {
          // console.log(resp.data);
          setState(resp.data);
          setTotalPage(resp.data.data?.suggestions?.total_pages);

          _axios
            .get(buildLink("getRecommandedPoints") + pageparam + "&limit=" + 10)
            .then((resp) => {
              if (resp.data.success) {
                setLoading(false);
                setSuggesstions(resp.data.data.suggestions.products);
                setTotalPage(resp.data.data.suggestions.total_pages)
              }

            });
        });
    }
  }, [page, stateAcc.loading]);

  function goTo() {
    router("/account/memberShipDetails");
  }

  function pageSetter(page) {
    setPage(page["selected"] + 1);
    router(
      `/account/memberShip?&page=${page["selected"] + 1}&limit=${limit}`
    );
  }
  const items = Array(14).fill("test"); 
  return (
    <div className="mobilecontainer sm:overflow-hidden">
      <div className=" text-dblack ">


        <SeoHandler
          data={{
            title: "Member Ship | Ishtari",
          }}
        />
       <div className="pb-2" >
       <div className="flex-row md:flex gap-3" style={{ height: '100%' }}>
          <div className={` ${width <= 650 ? 'hidden' : ''}`} >
            <UserSidebar active={"membership"} />
            {items.map((item, index) => (
            <div 
              key={index} 
              className="bg-white text-white" 
              style={{ flex: 1 }}>
              {item}
            </div>
          ))}

          
          </div>



            <div className="w-full mx-auto h-full">
              {state && <div
                className=" text-white px-5 h-1/3"
                style={{
                  backgroundColor: "#f5f5f1",
                  backgroundImage:
                    "linear-gradient(215deg, #f5f5f1 -50%, #e50914 90%)",
                }}
              >
                <p className="text-lg pr-semibold py-4 w-full">MemberShip </p>

                <div className="flex flex-row pb-7 relative">
                  <div
                    className="rounded-full w-28 h-22 mobile:w-1/12 mobile:h-auto text-4xl h-mino-8 pr-semibold border-4 border-white text-dblack  flex  justify-center align-middle items-center uppercase opacity-90"
                    // style={{ backgroundColor: state?.data?.current_level }}

                    style={{
                      backgroundColor: state?.data?.current_level,
                      backgroundImage:
                        "linear-gradient(180deg, " +
                        `${state?.data?.current_level}` +
                        " 50%, white 100%)",
                    }}
                  >
                    {stateAcc.username
                      ?.replace(/\s+/g, "")
                      .charAt(0)
                      .toUpperCase() ||
                      stateAcc?.lastname
                        ?.replace(/\s+/g, "")
                        .charAt(0)
                        .toUpperCase()}
                  </div>
                  <div className="flex flex-col px-5 w-full">
                    <div className="flex items-center gap-5">
                      <div className="flex text-white text-d18 font-extrabold first-letter:uppercase">
                        {" "}
                        <span className=" first-letter:uppercase">
                          {" "}
                          {
                            stateAcc?.username}{" "}
                        </span>
                        <span className="pl-1 first-letter:uppercase">
                          {" "}
                          {
                            stateAcc.lastname}{" "}
                        </span>
                      </div>
                      <div className="flex items-center p-1 justify-center text-white text-d12  mt-1 border-[1px] rounded-md">
                        <AiOutlineStar className="mt-0.5 mr-1" />{" "}
                        {state?.data?.current_level} Member
                      </div>
                    </div>
                    <div className="flex mt-2">
                      <div className="h-1 w-full bg-dTransparentWhite1 mt-2">
                        <div
                          className="h-1 bg-white"
                          style={{
                            width:
                              (state?.data?.current_point_precentage * 100) + "%",
                          }}
                        ></div>
                      </div>
                      <div className="px-5 text-d14">
                        {(state?.data?.current_point_precentage * 100).toFixed(2)}%
                      </div>
                    </div>
                    <div className="text-d14 ">
                      Earn{" "}
                      {state?.data.points_needed
                        ? state?.data.points_needed
                        : "zero"}{" "}
                      more points to become a {state.data.next_level} Member
                    </div>
                  </div>
                  <div
                    onClick={goTo}
                    className="flex  justify-between starts-div cursor-pointer absolute z-10 rounded-lg bg-white text-dgreyProduct text-sm md:text-d14 shadow-md  px-6 py-1.5 -bottom-10 w-full"
                  >
                    <div className="flex-col">
                      <div>{state?.data?.current_level} Member</div>
                      <div>Rewards</div>
                    </div>
                    <div className="flex align-middle items-center">
                      <AiOutlineRight className="text-d20" />
                      <AiOutlineRight className="text-d20" />
                    </div>
                  </div>
                </div>
              </div>
              }
              <div className="text-xl  text-dblackOverlay3 opacity-80  px-2 mt-16">
                Hot picks for you
              </div>
              {loading ? (
                <div className="flex justify-center w-fit mx-auto">
                  <PointsLoader />
                </div>
              ) :
              <div
              className={`grid transition-all ${
               
                     "grid-cols-2 xl:grid-cols-5 lg:grid-cols-5 mobile:pt-2"
            
              }`}
            >
                {suggestions?.map((p) => (
                  <div className="p-1" key={p.product_id}>
                    <SingleProducts
                      item={p}
               
                     
                    />
                  </div>
                ))}
            </div>
            
              }

              {totalPage > 48 && (
                <ReactPaginate
                  pageCount={Math.ceil(totalPage / limit)}
                  containerClassName={"pagination"}
                  onPageChange={pageSetter}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={1}
                  previousLabel={"<"}
                  nextLabel={">"}
                  activeClassName={"active-pagination"}
                  forcePage={router ? parseInt(page) - 1 : 0}
                ></ReactPaginate>
              )}
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}
