import React, { useState } from "react";
import { AiFillTwitterCircle } from "react-icons/ai";
import { MdIosShare, MdOutlineClose ,MdShare} from "react-icons/md";
import { FaShareAlt } from 'react-icons/fa';

import {
  BsFacebook,
  BsFillShareFill,
  BsInstagram,
  BsPinterest,
} from "react-icons/bs";
import { BiLink } from "react-icons/bi";
import { HiOutlineMail } from "react-icons/hi";
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  PinterestShareButton,
} from "react-share";
import { IoLogoWhatsapp } from "react-icons/io";
import { FullOverlay } from "../components/Overlay";

function ShareSocial(props) {
  const { image, share, name, wrapperRef, linkToCopy, whereToUse } = props;
  const [copied, setCopied] = useState(false);
  const [mobileShare, setMobileShare] = useState(false);
  const [overlay, setOverlay] = useState(false);

  function copyLink() {
    const el = document.createElement("input");
    el.value = linkToCopy;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  }

  function toggleMobileShare() {
    setMobileShare(!mobileShare);
    setOverlay(!overlay);
  }



  const catalogTestposition = whereToUse === 'catalogtest' ? 'top-2' : '';
  const catalogTestSize = whereToUse === 'catalogtest' ? 'w-2  h-2' : '';


  return (
    <div>
      <div className="share-desktop hidden mobile:block">
        <div
          className={`absolute bg-white w-8 h-8 z-10 cursor-pointer rounded-full flex justify-center items-center top-0 right-auto border border-dgreyZoom 
             ${catalogTestposition}`}
        >
          <FaShareAlt />
        </div>
        {share && (
          <div
            className="shareList z-10 md:z-20 w-40 visible top-10 right-auto inline-block absolute p-2 "
            ref={wrapperRef}
          >
            <div
              className="overflow-hidden border border-dinputBorder h-full w-full rounded bg-white "
              style={{ boxShadow: "0 2px 4px rgb(0 0 0 / 13%)" }}
            >
              <div className="h-auto overflow-y-hidden bg-dplaceHolder text-left overflow-x-hidden">
                {/* header */}
                <div className="text-left bg-white">
                  <div className="border-b border-dplaceHolder cursor-pointer hover:text-dblue">
                    <a
                      href={`https://api.whatsapp.com/send?text=${linkToCopy}`}
                      className="pl-1 flex items-center"
                    >
                      <IoLogoWhatsapp className="w-5 h-5 mr-4 text-dwhatsapp" />
                      <span className="leading-9">Whatsapp</span>
                    </a>
                  </div>
                  <div className="border-b border-dplaceHolder cursor-pointer hover:text-dblue">
                    <EmailShareButton
                      subject={name}
                      body={name}
                      url={linkToCopy}
                    >
                      <div className="pl-1 flex items-center">
                        <HiOutlineMail className="w-5 h-5 mr-4 " />
                        <span className="leading-9">Email</span>
                      </div>
                    </EmailShareButton>
                  </div>
                  <div className="border-b border-dplaceHolder cursor-pointer hover:text-dblue">
                    <FacebookShareButton url={linkToCopy} quote={name}>
                      <div className="pl-1 flex items-center">
                        <BsFacebook className="w-5 h-5 mr-4 text-dfacebook" />
                        <span className="leading-9"> Facebook</span>
                      </div>
                    </FacebookShareButton>
                  </div>
                  <div className="border-b border-dplaceHolder cursor-pointer hover:text-dblue">
                    <TwitterShareButton url={linkToCopy} title={name}>
                      <div className="pl-1 flex items-center">
                        <AiFillTwitterCircle className="w-5 h-5 mr-4 text-dtwitter" />
                        <span className="leading-9">Twitter</span>
                      </div>
                    </TwitterShareButton>
                  </div>
                  <div
                    className="cursor-pointer hover:text-dblue"
                    onClick={() => copyLink()}
                  >
                    <div className="pl-1 flex items-center">
                      <BiLink className="w-5 h-5 mr-4" />
                      <span className="leading-9">
                        {!copied ? "Copy link" : "Copied!"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="share-mobile mobile:hidden">
      <div
      
        onClick={() => {
          toggleMobileShare();
        }}
      >
        <MdShare className="w-5 h-5 md:w-6 md:h-6 block mb-0.5 md:mb-1" />
      </div>
        <div
          className={`fixed ${
            !mobileShare ? "-bottom-[200%]" : "bottom-0"
          } transition-all top-auto right-0 left-0 z-30 `}
        >
          <div
            className="cursor-pointer h-80 absolute right-0 bottom-0 left-0  "
            style={{ maxHeight: "507px" }}
          >
            <div
              className="absolute w-12 h-12 z-20 top-6 mt-1 -right-2"
              onClick={() => toggleMobileShare()}
            >
              <MdOutlineClose className="w-6 h-6 block " />
            </div>
            <div className="h-full overflow-y-auto bg-white overflow-x-hidden rounded-xl">
              <div className="block h-full overflow-y-scroll rounded-xl bg-white">
                <div className="rounded-xl relative">
                  <div className="h-12 pt-5 pb-1 pl-5 text-dDarkgrey w-full text-md leading-5 font-semibold mt-3">
                    <span>Share this product with friends</span>
                  </div>
                  <span className="cursor-pointer ">
                    <div>
                      <div className="h-12 w-full ">
                        <a
                          href={`https://api.whatsapp.com/send?text=${linkToCopy}`}
                          className="pl-6 text-dblack text-lg leading-10 h-12 flex items-center"
                        >
                          <IoLogoWhatsapp className="w-5 h-5 mr-4 text-dwhatsapp" />
                          <span className="leading-9">Whatsapp</span>
                        </a>
                      </div>
                    </div>
                  </span>
                  <span className="cursor-pointer">
                    <div>
                      <div className="h-12 w-full ">
                        <EmailShareButton
                          url={linkToCopy}
                          subject={name}
                          body={name}
                        >
                          <div className="pl-6 text-dblack text-lg leading-10 h-12 flex items-center">
                            <HiOutlineMail className="w-5 h-5 mr-4 " />
                            <span className="leading-9">Email</span>
                          </div>
                        </EmailShareButton>
                      </div>
                    </div>
                  </span>
                  <span className="cursor-pointer">
                    <div>
                      <div className="h-12 w-full ">
                        <FacebookShareButton
                          url={linkToCopy}
                          quote={name}
                        >
                          <div className="pl-6 text-dblack text-lg leading-10 h-12 flex items-center">
                            <BsFacebook className="w-5 h-5 mr-4 text-dfacebook" />
                            <span className="leading-9">Facebook</span>
                          </div>
                        </FacebookShareButton>
                      </div>
                    </div>
                  </span>
                  <span className="cursor-pointer">
                    <div>
                      <div className="h-12 w-full ">
                        <TwitterShareButton
                          url={linkToCopy}
                          title={name}
                        >
                          <div className="pl-6 text-dblack text-lg leading-10 h-12 flex items-center">
                            <AiFillTwitterCircle className="w-5 h-5 mr-4 text-dtwitter" />
                            <span className="leading-9">Twitter</span>
                          </div>
                        </TwitterShareButton>
                      </div>
                    </div>
                  </span>
                  <span
                    className="cursor-pointer"
                    onClick={() => {
                      navigator.clipboard.writeText(linkToCopy);
                      setCopied(true);
                      setTimeout(() => setCopied(false), 3000);
                    }}
                  >
                    <div>
                      <div className="h-12 w-full ">
                        <div className="pl-6 text-dblack text-lg leading-10 h-12 flex items-center">
                          <BiLink className="w-5 h-5 mr-4" />
                          <span className="leading-9">
                            {!copied ? "Copy link" : "Copied!"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {overlay && (
          <div
            onClick={() => {
              toggleMobileShare();
            }}
          >
            <FullOverlay />
          </div>
        )}
      </div>
    </div>
  );
}

export default ShareSocial;
