import { useLocation, useNavigate } from "react-router";
import { useState, useEffect } from "react";
import _axios from "../axios";
import PointsLoader from "../components/PointsLoader";
import Cookies from "js-cookie";
import { FaAngleDoubleRight } from "react-icons/fa";
import paymenterror from "../assets/images/paymenterror.png";

const ValidatePayment = () => {
  const navigate = useNavigate();
  const location = useLocation();  // Use location to get current URL

  const queryParams = new URLSearchParams(location.search);
  const status = queryParams.get("status");
  const order_id = queryParams.get("order_id");
  const message = queryParams.get("message");

  const [loading, setLoading] = useState(true);
  const [successPay, setSuccessPay] = useState(false);
  const [error, setError] = useState(null);

  // Log the query parameters for debugging purposes
  useEffect(() => {
  

    // Check if the URL parameters are valid
    if (!status || !order_id) {
      setLoading(false);
      setError("Invalid request. Missing required parameters.");
    } else {
      if (status === "failureRedirect") {
        setLoading(false);
        setError(message || "An unknown error occurred.");
      } else if (status === "successRedirect" && order_id) {
        const payment_form_data =  Cookies.get("payment_form_data");
        const payment_form = payment_form_data ? JSON.parse(payment_form_data) : null;
        
        if (payment_form && order_id == payment_form.order_id) {
          confirmOrder(payment_form);
        } else {
          setLoading(false);
          setError("Order ID mismatch or missing payment data.");
        }
      }
    }
  }, [status, order_id, message]);

  function confirmOrder(payment_form) {
    const confirm_url = payment_form.confirm_url;
    _axios.get(`${confirm_url}&order_id=${order_id}`)
      .then((res) => {
        if (res.data.success) {
          successOrder(payment_form.success_url);
        } else {
          Cookies.remove("payment_form_data");
          setLoading(false);
          setError("Payment confirmation failed.");
        }
      })
      .catch((err) => {
        setLoading(false);
        setError("Failed to confirm order. Please try again.");
        console.error('Error during order confirmation:', err);
      });
  }

  function successOrder(url) {
    _axios.get(url)
      .then((response) => {
        setLoading(false);
        setSuccessPay(true);
        Cookies.remove("payment_form_data");

        if (response.data.success) {
          navigate("/success", { state: { orderData: response.data } });
        } else {
          setError("Order success confirmation failed.");
        }
      })
      .catch((err) => {
        setLoading(false);
        setError("Failed to fetch success confirmation.");
        console.error('Error during success confirmation:', err);
      });
  }

  return (
    <div className="py-10 w-full h-screen flex justify-center text-center">
      {loading ? (
        <PointsLoader />
      ) : error ? (
        <div className="flex p-2 justify-center gap-5 flex-col w-full h-[70vh] rounded-md">
          <div className="relative mx-auto w-52">
            <img src={paymenterror} alt="Payment Error" />
          </div>
          <h2 className="text-4xl font-bold">{error}</h2>
          <button
            onClick={() => navigate("/")}
            className="transition-all group duration-150 w-fit px-20 mx-auto text-2xl py-5 bg-white shadow-md flex flex-row justify-center gap-4 rounded-md"
          >
            <span className="group-hover:text-dbase text-dblack transition-all duration-150">
              Home Page
            </span>
            <FaAngleDoubleRight className="group-hover:text-dbase text-dblack transition-all duration-300 my-auto animate-bounceRL" />
          </button>
        </div>
      ) : (
        <div className="text-xl">Something went wrong. Please try again later.</div> // Fallback error message
      )}
    </div>
  );
};

export default ValidatePayment;
