import React, { useContext, useEffect, useRef, useState } from "react";
import imageCompression from "browser-image-compression";
import _axios from "../axios";
import buildLink from "../urls";
import { FaPlus, FaTrash } from "react-icons/fa";
import useDeviceSize from "../components/useDeviceSize";
import { useNavigate, useParams } from "react-router-dom"; 
import Loader from "../components/Loader";
import { SuggestionContext } from "../contexts/SuggestionContext";
import { toast } from "react-toastify";

function SuggestionDetails({ onCancel }) {
  const navigate = useNavigate(); 
  const { suggestionId } = useParams(); 
  const [suggImgs, setSuggImgs] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [exceededMaxNb, setExceededMaxNb] = useState(false);

  const [success, setSuccess] = useState("");
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [dataImgs, setDataImgs] = useState([]);
  const [deletedImgs, setDeletedImgs] = useState([]);
  const [leftImgs, setLeftImgs] = useState(5);
  const [deleteMsg, setDeleteMsg] = useState("");
  const [deleteErr, setDeleteErr] = useState("");
  const hiddenFileInput = useRef(null);
  const [width] = useDeviceSize();
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (suggestionId) {
      setIsEdit(true);
      _axios
        .get(
          buildLink("suggestion", undefined, undefined) +
            `&suggestion_id=${suggestionId}`
        )
        .then((response) => {
          if (response.data.success) {
            setName(response.data.data.name);
            setDesc(response.data.data.description);
            setDataImgs(response.data.data.images);
            const left = 5 - response.data.data.images.length;
            setLeftImgs(left);
          }
        });
    }
  }, [suggestionId]);

  useEffect(() => {
    const left = 5 - dataImgs.length;
    setLeftImgs(left);
  }, [dataImgs]);

  const defaultOptions = {
    maxSizeMB: 1,
  };

  function compressFile(imageFile, options = defaultOptions) {
    return imageCompression(imageFile, options);
  }

  async function onFileChange(event) {
    const selectedFiles = event.target.files;
    const compressedImages = [];
    if (event.target.files.length === leftImgs) {
      for (const image of selectedFiles) {
        try {
          const compressedImageFile = await compressFile(image);
          var file = new File([compressedImageFile], image.name);
          compressedImages.push(file);
        } catch (error) {
          console.error("Error compressing image:", error);
        }
      }
      setSuggImgs(compressedImages);
      setExceededMaxNb(false);
    } else if (event.target.files.length > 5 || event.target.files.length > leftImgs) {
      for (let i = 0; i < leftImgs; i++) {
        try {
          const compressedImageFile = await compressFile(event.target.files[i]);
          var file = new File([compressedImageFile], event.target.files[i].name);
          compressedImages.push(file);
        } catch (error) {
          console.error("Error compressing image:", error);
        }
      }
      setSuggImgs(compressedImages);
      setExceededMaxNb(true);
      setTimeout(() => {
        setExceededMaxNb(false);
      }, 3500);
    } else {
      setExceededMaxNb(false);
      for (const image of selectedFiles) {
        try {
          const compressedImageFile = await compressFile(image);
          var file = new File([compressedImageFile], image.name);
          compressedImages.push(file);
        } catch (error) {
          console.error("Error compressing image:", error);
        }
      }
      setSuggImgs([...suggImgs, ...compressedImages]);
    }
  }

  const handleFileLimit = () => {
    return suggImgs.length >= 5 || dataImgs.length >= 5 || suggImgs.length + dataImgs.length >= 5;
  };

  const handleImageUpload = () => {
    hiddenFileInput.current.click();
  };

  const submitRequest = (e) => {
    e.preventDefault();
    // dispatch({ type: "setLoading", payload: true });
    var formData = new FormData();

    formData.append("name", name);
    formData.append("description", desc);
    formData.append("source_id", 1);

    if (!isEdit) {
      suggImgs.slice(0, 5).map((image, index) => {
        formData.append(`images[${index}]`, image);
      });
      _axios
        .post(buildLink("suggestion", undefined, undefined), formData)
        .then((response) => {
          if (response.data.success) {
            // setSuccess(response.data.message);
            setTimeout(() => {
                toast(response.data.message, { type: "success" });
               window.location.reload();
              }, 500);
            
            // dispatch({ type: "setLoading", payload: false });
          }
        });
    } else {
      deletedImgs.map((path, index) => {
        formData.append(`old_images[${index}]`, path);
      });

      suggImgs.slice(0, leftImgs).map((image, index) => {
        formData.append(`images[${index}]`, image);
      });

      _axios
        .post(
          buildLink("suggestion", undefined, undefined) +
            `&suggestion_id=${suggestionId}`,
          formData
        )
        .then((response) => {
          if (response.data.success) {
            setSuccess(response.data.message);
            // dispatch({ type: "setLoading", payload: false });
            navigate("/account/suggestion");
          }
        });
    }
  };

  const deleteSuggestion = (sugg_id) => {
    setDeleteErr("");
    setDeleteMsg("");
    _axios
      .delete(
        buildLink("suggestion", undefined, undefined) +
          `&suggestion_id=${sugg_id}`
      )
      .then((response) => {
        if (response.data.success) {
          setDeleteMsg("Suggestion deleted successfully");
          setTimeout(() => {
            setDeleteMsg("");
            navigate("/account/suggestion");
          }, 3000);
        } else {
          setDeleteErr(response.data?.errors[0]?.errorMsg);
        }
      });
  };

  return (
    <div className="relative">
      {isLoading && (
        <div className="bg-dgreen text-white py-2 px-6 rounded pr-semibold fixed bottom-10 right-3 z-10">
          {success}
        </div>
      )}
      {success.length > 0 && (
        <div className="bg-dgreen text-white py-2 px-6 rounded pr-semibold fixed bottom-10 right-3 z-10">
          {success}
        </div>
      )}
      {deleteMsg.length > 0 && (
        <div className="bg-dgreen text-white py-2 px-6 rounded pr-semibold fixed bottom-10 right-3 z-10">
          {deleteMsg}
        </div>
      )}
      {deleteErr.length > 0 && (
        <div className="bg-dbase text-white py-2 px-6 rounded pr-semibold fixed bottom-10 right-3 z-10">
          {deleteErr}
        </div>
      )}
      <div className="flex-row md:flex">
        <div className="w-full md:w-4/5">
          <div className="text-d20 pr-semibold py-5 w-full ">
            {!isEdit ? "Add new suggestion" : "Edit your suggestion"}
          </div>
          <form onSubmit={(e) => submitRequest(e)}>
            <div className="pb-5">
              <div>
                <div>Product Name:</div>
                <input
                  type="text"
                  defaultValue={name}
                  placeholder="Please enter product name"
                  onChange={(e) => setName(e.target.value)}
                  className="p-2 border border-dgrey1 rounded-md w-full lg:w-[656px] mt-4 outline-none"
                  required
                />
              </div>
            </div>
            <div>
              <div className="pb-5">
                <div>Product Description:</div>
                <textarea
                  type="text"
                  defaultValue={desc}
                  onChange={(e) => setDesc(e.target.value)}
                  placeholder="We take your feedback seriously..."
                  required
                  rows={width > 1024 ? 8 : 6}
                  cols={width > 1024 ? 80 : width > 650 ? 50 : 30}
                  className="p-2 border border-dgrey1 rounded-md w-full lg:w-[656px] mt-4 outline-none"
                />
              </div>
            </div>
            <div>
              <div className="pb-5">
                <div>Product Images:</div>
                <div className="flex item-start md:items-center gap-2 mt-4">
                  <div
                    className={`border-2 border-dslate border-dashed relative h-[90px] w-[90px] min-w-[90px] md:min-w-[80px] md:h-20 md:w-20  ${
                      (handleFileLimit() && "opacity-50", !handleFileLimit() && "cursor-pointer")
                    }`}
                    onClick={() => handleImageUpload()}
                  >
                    <div className="add_images_upload">
                      <FaPlus
                        className={`w-4 h-4 text-dblue  ${
                          handleFileLimit() && "opacity-40"
                        }`}
                      />
                      <input
                        type="file"
                        name="images"
                        multiple
                        onChange={(e) => onFileChange(e)}
                        disabled={handleFileLimit()}
                        className="hidden"
                        ref={hiddenFileInput}
                        accept="image/png, image/jpg"
                      />
                    </div>
                  </div>
                  <div className="flex flex-wrap gap-2 justify-start">
                    {suggImgs?.slice(0, 5).map((img, index) => (
                      <div className="relative " key={Math.random()}>
                        <div className="w-[90px] relative overflow-hidden h-[90px] md:h-20 md:w-20">
                          <img
                            src={URL.createObjectURL(img)}
                            className=" object-cover w-full h-full"
                            alt={URL.createObjectURL(img)}
                            loading="lazy"
                          />
                        </div>
                        <button
                          className="absolute z-10 bottom-0 w-full align-middle"
                          style={{
                            backgroundColor: "#00000066",
                          }}
                          onClick={() => {
                            hiddenFileInput.current.value = null;
                            setSuggImgs(suggImgs.filter((e) => e !== img));
                          }}
                        >
                          <FaTrash className="w-4 h-4 my-1 mr-auto ml-auto text-white " />
                        </button>
                      </div>
                    ))}
                  </div>

                  {dataImgs.length > 0 && (
                    <div className="flex flex-wrap gap-2 justify-start">
                      {dataImgs?.slice(0, 5).map((img, index) => (
                        <div className="relative " key={Math.random()}>
                          <div className="w-[90px] relative overflow-hidden h-[90px] md:h-20 md:w-20">
                            <img
                              src={img}
                              className=" object-cover  w-full h-full"
                              alt={img}
                              loading="lazy"
                            />
                          </div>
                          <button
                            className="absolute z-10 bottom-0 w-full align-middle"
                            style={{
                              backgroundColor: "#00000066",
                            }}
                            onClick={() => {
                              setDataImgs(dataImgs.filter((e) => e !== img));
                              setDeletedImgs((current) => [
                                ...current,
                                dataImgs[index],
                              ]);
                            }}
                          >
                            <FaTrash className="w-4 h-4 my-1 mr-auto ml-auto text-white " />
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div className="pr-light text-[#9ea4b0] text-sm mt-4">
                  You can upload a maximum of 5 files. Individual image size
                  should not exceed 1MB and supported file formats are PNG and
                  JPG
                </div>
              </div>
              {exceededMaxNb && (
                <div className="text-dbase pr-semibold py-2">
                  Number of selected images exceeds maxNumber "5"
                </div>
              )}
            </div>
            <div className="flex items-center gap-8">
              <button
                onClick={onCancel}
                className="px-4 py-2 bg-white text-gray-700 rounded-lg "
              >
                Cancel
              </button>

              {!isEdit ? (
                <button
                  className="rounded-md text-white px-8 py-1.5 w-full md:w-60"
                  style={{
                    background:
                      "linear-gradient(98.3deg, rgb(204, 0, 0) 30.6%, rgb(255, 153, 153) 97.7%)",
                  }}
                >
                  {/* {state.loading ? (
                    <div className=" flex justify-center text-center w-full">
                      <div className="w-[30px]">
                        <Loader />
                      </div>
                    </div>
                  ) : ( */}
                    <>Submit</>
                  {/* )} */}
                </button>
              ) : (
                <div className="flex items-center gap-3">
                  <button
                    onClick={(e) => submitRequest(e)}
                    className="rounded-md shadow-md border border-dgreyZoom px-8 py-1.5 w-1/2 md:w-60"
                  >
                    {/* {state.loading ? (
                      <div className=" flex justify-center text-center w-full">
                        <div className="w-[30px]">
                          <Loader />
                        </div>
                      </div>
                    ) : ( */}
                      <>Edit</>
                    {/* )} */}
                  </button>
                  <div
                    onClick={() => deleteSuggestion(suggestionId)}
                    className="cursor-pointer bg-dbase text-white w-1/2 md:w-60 rounded-md text-center py-1.5"
                  >
                    Delete
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SuggestionDetails;
