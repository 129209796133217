import _axios from "../../axios";
import PointsLoader from "../../components/PointsLoader";
import useDeviceSize from "../../components/useDeviceSize";
import buildLink from "../../urls";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import UserSidebar from "../../components/UserSidebar";
import { BsChevronLeft, BsChevronRight, BsPlus } from "react-icons/bs";
import ReactPaginate from "react-paginate";
import SuggestionDetails from "../../components/SuggestionForm";
import { useNavigate, useLocation } from "react-router-dom";
import SuggesstionImage from '../../assets/images/cutomer-suggestion.png';

function Suggestions() {
  const navigate = useNavigate();
  const location = useLocation();
  const [width] = useDeviceSize();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(new URLSearchParams(location.search).get("page") || 1);
  const [deleteMsg, setDeleteMsg] = useState("");
  const [deleteErr, setDeleteErr] = useState("");
  const [showSuggestionForm, setShowSuggestionForm] = useState(false);

  const handleClick = () => {
    setShowSuggestionForm(true);
  };

  const path = ""; 

  useEffect(() => {
    setLoading(true);
    _axios
      .get(buildLink("suggestion", undefined, undefined) + `&page=${page}`)
      .then((response) => {
        if (response.data.success) {
          setData(response.data.data);
        }
        setLoading(false);
      });
  }, [page]);

  const handlePageClick = (event) => {
    const new_page = parseInt(event.selected) + 1;

    setPage(new_page);
    navigate({
      pathname: location.pathname,
      search: `?page=${new_page}`,
    });
  };
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1024);
  return (
    <div className="pb-4">
      <div className="flex-row md:flex min-h-screen">
      <div
        className={`w-full mb-4 md:w-1/5  -ml-3 ${
         !isLargeScreen ? "hidden" : ""
        }`}
      >
            <UserSidebar />
            <div className="h-16 bg-white w-[264px]"></div>
          </div>
        <div className="w-full  px-2 md:px-0 mb-5 relative">
          <div>
            {/* banner */}
            <div className="relative justify-center hidden mobile:flex">
              <img
                src={SuggesstionImage}
                width={1220}
                height={320}
              />
            
            </div>
            

            <div className="mobile:hidden">
              <img
               src={SuggesstionImage}
                width={359}
                height={323}
                className="w-full"
              />
                {!showSuggestionForm && (
               <div className="flex items-center justify-center" style={{transform:"translateY(-50%)"}}>
               <button
                 onClick={handleClick}
                 className="flex gap-1 hover:text-dbase transition-allduration-300 bg-white border border-dgreyZoom text-dblack lg:text-md  text-sm shadow-md px-4 py-1.5 rounded-md cursor-pointer"
               >
                 Add New Suggestion <BsPlus className="w-5 h-5" style={{transform:isLargeScreen?"translateY(11%)":"translateY(7%)"}}/>
               </button>
             </div>
             
              )}
            </div>
            {loading ? (
              <div className="h-screen">
                <PointsLoader />
              </div>
            ) : (
              !showSuggestionForm && (
                <div className="bg-white">
                  {data?.suggestions?.length > 0 ? (
                    data.suggestions.map((sugg) => (
                      <div
                        className="mb-5 border-b-2 border-dinputBorder mx-2 md:mx-5"
                        key={sugg.customer_suggestion_ID}
                      >
                        <div className="flex justify-between">
                          <div className="flex items-center gap-6">
                            <div>
                              {sugg?.images.length > 0 ? (
                                <img
                                  src={sugg?.images[0]}
                                  width={100}
                                  height={100}
                                  alt={"suggestion image"}
                                />
                              ) : (
                                <div className="w-[100px] h-[100px]"></div>
                              )}
                            </div>
                            <div className="flex flex-col">
                              <div>{sugg.name}</div>
                              <div className="pr-light text-light">
                                {sugg.status}
                              </div>
                            </div>
                          </div>
                          <Link
                            to={`/account/suggestion/${sugg.customer_suggestion_ID}/edit`}
                            className="flex items-center text-dgreyAddress text-sm md:mr-5 gap-1.5 cursor-pointer"
                          >
                            <div>Edit</div>
                            <BsChevronRight />
                          </Link>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="flex flex-col items-center">
                      {/* Add any additional content here */}
                    </div>
                  )}
                </div>
              )
            )}
            
          </div>
          <div className="px-3 lg:px-0">
              {showSuggestionForm && <SuggestionDetails onCancel={() => setShowSuggestionForm(false)} />}
            </div>
          {data.totalpages > 1 && (
            <div className="h-12">
              <ReactPaginate
                className={"category-pagination"}
                breakLabel="..."
                nextLabel=">"
                onPageChange={handlePageClick}
                pageRangeDisplayed={width < 650 ? 1 : 2}
                marginPagesDisplayed={width < 650 ? 1 : 2}
                pageCount={data?.totalpages}
                previousLabel="<"
                activeClassName={"active-pagination-category"}
                renderOnZeroPageCount={null}
                forcePage={parseInt(new URLSearchParams(location.search).get("page")) - 1 || 0}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Suggestions;
